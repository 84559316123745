<template>
  <BaseList :list-query="list" route="style" locale-section="pages.styles" />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "StyleList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: styles {
            id
            name: style
            style
            sv
            en
            tpq
            taq
          }
        }
      `
    };
  }
};
</script>
